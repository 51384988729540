import { FilterType } from "../../types"
import { makeStyles } from "@material-ui/core/styles"
import { Box, Typography } from "@material-ui/core"
import StyledCheckbox from "../../../../components/StyledCheckbox"
import React from "react"
import clsx from "clsx"
import useTheme from "@material-ui/core/styles/useTheme"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import { motion } from "framer-motion"


export interface Props {
  title: string,
  filterType: FilterType,
  options: any[],
  optionsCheck: any,
  optionIdKey: string,
  optionDisplayKey: string
  light: boolean
  onOptionChanged: (filterType: FilterType, optionId: string, optionDisplay: string, checked: boolean) => void
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "block",
    color: "black",
    [theme.breakpoints.up("xl")]: {
      marginTop: theme.spacing(2.5),
      marginBottom: theme.spacing(2)
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(2)
    }
  },
  title: {},
  inverse: {
    color: "white"
  }
}))

const variants = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 }
    }
  },
  closed: {
    y: 50,
    opacity: 0,
    transition: { duration: 0 }
  }
}


export default function Filter(props: Props) {
  const classes = useStyles()
  const theme = useTheme()
  const xsScreen = useMediaQuery(theme.breakpoints.down("xs"))

  const rootStyle = clsx({
    [classes.root]: true,
    [classes.inverse]: props.light
  })

  function onChange(optionId: string, optionDisplay: string, checked: boolean) {
    props.onOptionChanged(props.filterType, optionId, optionDisplay, checked)
  }


  function filterUI() {
    return <div
      className={rootStyle}>
      <motion.div variants={variants}>
        <Typography className={classes.title} variant={xsScreen ? "h3" : "body1"}>{props.title}</Typography>
      </motion.div>
      {props.options.map((option, index, options) => (
        <motion.div key={option[props.optionIdKey]}
                    variants={variants}>
          <Box mt={{ xs: (index == 0 ? 1.5 : 0), sm: (index == 0 ? 1 : -1), xl: (index == 0 ? 2 : 0.5) }}>
            <StyledCheckbox
              light={props.light}
              checked={props.optionsCheck[option[props.optionIdKey]] ?? false}
              label={option[props.optionDisplayKey]}
              onChange={(checked) => onChange(option[props.optionIdKey], option[props.optionDisplayKey], checked)}/>
          </Box>
        </motion.div>
      ))}
    </div>
  }

  return filterUI()


}

