import { makeStyles } from "@material-ui/core/styles"
import React from "react"
import { Typography } from "@material-ui/core"
import { useSelector } from "react-redux"
import { RootState } from "../../../redux/store"
import Sort from "./Sort/Sort"


const useStyles = makeStyles((theme) => ({
  root: {
    color: "black",
    backgroundColor: "#f5f5f5",
    paddingLeft: theme.spacing(3),
    paddingRight: "4%",
    height: theme.spacing(8),
    textTransform: "uppercase",
    display: "flex",
    position: "relative",
    alignContent: "center",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(6)
    },
    [theme.breakpoints.down("xs")]: {
      paddingRight: theme.spacing(2)
    }
  },
  titleSection: {
    fill: "1",
    paddingRight: theme.spacing(1),
    maxWidth: `calc(100% - 150px)`,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    [theme.breakpoints.down("xs")]: {
      maxWidth: `calc(100% - 120px)`
    }
  },
  ellipsis: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
  }
}))


export default function Header() {
  const title = useSelector((state: RootState) => state.browse.normalWorkoutsTitle)
  const breadcrumbs = useSelector((state: RootState) => state.browse.filterBreadcrumbs)

  const classes = useStyles()
  return <div className={classes.root}>
    <div className={classes.titleSection}>
      <Typography className={classes.ellipsis} variant={"subtitle2"}>{breadcrumbs}</Typography>
      <Typography className={classes.ellipsis} variant={"h2"}>{title}</Typography>
    </div>
    <Sort/>
  </div>

}