import { makeStyles } from "@material-ui/core/styles"
import Backdrop from "@material-ui/core/Backdrop"
import CloseButton from "../../../../components/CloseButton"
import React, { useEffect, useState } from "react"
import FilterButton from "./FilterButton"
import FilterStrip from "./FilterStrip"
import StyledButton from "../../../../components/StyledButton"
import { useDispatch, useSelector } from "react-redux"
import {
  applyTransientFilters,
  clearFilters,
  initTransientFilters,
  transientFilterValueChanged
} from "../../browseSlice"
import { FilterType } from "../../types"
import { RootState } from "../../../../redux/store"
import { motion } from "framer-motion"
import { WORKOUTS_HOME } from "../../../routing/Locations"


const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: "rgba(0, 0, 0, 0.95)",
    display: "block",
    overflowY: "auto",
    overflowX: "hidden"
  },
  filterHolder: {
    position: "relative"
  },
  filter: {
    padding: theme.spacing(0, 4, 6, 4)
  },
  buttonHolder: {
    width: "100%",
    position: "fixed",
    bottom: 0,
    left: 0,
    display: "flex",
    flexGrow: 1
  },

  button: {
    width: "50%"
  }
}))

const variants = {
  open: {
    transition: { staggerChildren: 0.05, delayChildren: 0.2 }
  },
  closed: {}
}

const buttonVariants = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 }
    }
  },
  closed: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 }
    }
  }
}


export default function OverlayFilter() {
  const [isOpen, setOpen] = useState(false)
  const classes = useStyles()
  const dispatch = useDispatch()

  function onOptionChanged(filterType: FilterType, id: string, display: string, active: boolean) {
    dispatch(transientFilterValueChanged({ filterType, id, display, active }))
  }

  const transientFilters = useSelector((state: RootState) => state.browse.transientFilters)

  function clearClicked() {
    dispatch(clearFilters())
    setOpen(false)
  }

  function applyClicked() {
    dispatch(applyTransientFilters())
    setOpen(false)
  }

  function filterButtonClicked() {
    dispatch(initTransientFilters())
    setOpen(true)
    if (window !== undefined)
      window.history.pushState("", "Browse", `${WORKOUTS_HOME}`)
  }

  function closeFilter() {
    setOpen(false)
  }

  useEffect(() => {
    if (isOpen) {
      const onPopState = () => {
        closeFilter()
      }
      window.addEventListener("popstate", onPopState)
      return () => {
        window.removeEventListener("popstate", onPopState)
      }
    }
  }, [isOpen])


  return (
    <motion.div
      initial={false}
      variants={variants}
      animate={isOpen ? "open" : "closed"}>
      <FilterButton clicked={filterButtonClicked}/>
      <Backdrop className={classes.backdrop} open={isOpen}>

        <div className={classes.filterHolder}>
          <div
            className={classes.filter}>
            <FilterStrip light={true} onOptionChanged={onOptionChanged} activeFilters={transientFilters}/>
          </div>
        </div>

        <motion.div
          variants={buttonVariants}
          className={classes.buttonHolder}>
          <StyledButton text={"Clear"} inverse={true} noBorder={true} className={classes.button}
                        onClick={clearClicked}/>
          <StyledButton text={"apply"} inverse={true} noBorder={true} className={classes.button}
                        onClick={applyClicked}/>
        </motion.div>

        <CloseButton clicked={closeFilter}/>
      </Backdrop>
    </motion.div>
  )
}