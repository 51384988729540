import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import FilterStrip from "./FilterStrip"
import { filterValueChanged } from "../../browseSlice"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../../../redux/store"
import { FilterType } from "../../types"

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: "240px",
    padding: theme.spacing(2, 3),
    [theme.breakpoints.down("xs")]: {
      display: "none"
    },
    [theme.breakpoints.up("xl")]: {
      minWidth: "350px"
    }
  }

}))


const SideFilter = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const activeFilters = useSelector((state: RootState) => state.browse.activeFilters)

  function onOptionChanged(filterType: FilterType, id: string, display: string, active: boolean) {
    dispatch(filterValueChanged({ filterType, id, display, active }))
  }

  return <div className={classes.root}>
    <FilterStrip light={false} onOptionChanged={onOptionChanged} activeFilters={activeFilters}/>
  </div>
}


export default SideFilter
