import React, { useEffect } from "react"
import { styled } from "@material-ui/core/styles"
import FeaturedSection from "./Featured/FeaturedSection/FeaturedSection"
import { useDispatch, useSelector } from "react-redux"
import { clean, clearSelectedWorkout, hydrateBrowseState, init, previewWorkoutById } from "./browseSlice"
import WorkoutsSection from "./NormalWorkouts/WorkoutsSection"
import { graphql, useScrollRestoration, useStaticQuery } from "gatsby"
import { RootState } from "../../redux/store"
import ActiveMemberWorkoutModeChoice from "./ActiveMemberWorkoutModeChoice"
import withLocation from "../../components/withLocation"
import MenuWithLogo from "../menu/MenuWithLogo"
// @ts-ignore
// @ts-ignore
import { AuthenticatedOrSSRRoute } from "../routing/Routes"
import { WorkoutVideo } from "./types"
import WorkoutSEO from "../../components/WorkoutSEO"
import { WORKOUTS_HOME } from "../routing/Locations"


const Container = styled("div")(({ theme }) => ({
  overflowX: "hidden",
  width: "100%",
  height: "100vh",
  backgroundColor: "white",
  flexGrow: 1,
  WebkitOverflowScrolling: "touch",
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {}
}))

export interface Props {
  wid?: string | null
  pageContext?: any
  search?: any
}


const Browse = (props: Props) => {
  const { tsId } = props.search
  const data = useStaticQuery(graphql`
      {
          allWorkout(sort: {fields: creationTime, order: DESC}) {
              nodes {
                  difficulties
                  durationCategory
                  categories
                  creationTime
                  id
                  instructors
                  mainDifficulty
                  rating
                  subtitle
                  thumbnailUrl
                  title
                  scheduleTitle
                  pageTitle
                  gifUrl
                  description
                  equipments
                  significantViews
                  localImage {
                      childImageSharp {
                          fluid(maxWidth: 640, webpQuality: 100, pngQuality: 100, jpegQuality: 100) {
                              ...GatsbyImageSharpFluid_withWebp
                          }
                      }
                  }
              }
          }
          allClassTimeslot(sort: {fields: classTimestamp, order: DESC}, limit: 200) {
              nodes {
                  id
                  wid
                  classTimestamp
              }
          }
      }
  `)

  const dispatch = useDispatch()
  dispatch(hydrateBrowseState({
    workouts: data.allWorkout.nodes,
    schedule: data.allClassTimeslot.nodes
  }))

  useEffect(() => {
    if (props.wid || props.pageContext) {
      const wid = props.wid ?? props.pageContext.id
      dispatch(previewWorkoutById({ wid, tsId }))
    }
  }, [props.wid, props.pageContext])

  useEffect(() => {
    dispatch(init())
    return () => {
      dispatch(clean())
    }
  }, [])


  return <AuthenticatedOrSSRRoute component={BrowseBody} workoutVideo={props.pageContext}/>
}

export default withLocation(Browse)

function BrowseBody({ workoutVideo }: { workoutVideo?: WorkoutVideo }) {
  const selectedWorkout = useSelector((state: RootState) => state.browse.selectedWorkout)
  const selectedType = useSelector((state: RootState) => state.browse.selectionType)
  const ulScrollRestoration = useScrollRestoration(`page-component-ul-list`)
  const dispatch = useDispatch()

  return (
    <div>
      <WorkoutSEO workoutVideo={workoutVideo} defaultTitle={`Classes`}/>
      <Container {...ulScrollRestoration}>
        <MenuWithLogo memberMenu={true} showWelcome={true} blackIcon={false}/>
        <FeaturedSection/>
        <WorkoutsSection/>
        <ActiveMemberWorkoutModeChoice workout={selectedWorkout} selectionType={selectedType}
                                       backurl={WORKOUTS_HOME}
                                       cleanup={() => dispatch(clearSelectedWorkout())}/>
      </Container>
    </div>
  )

}