import React from "react"
import { makeStyles, withStyles } from "@material-ui/core/styles"
import Table from "@material-ui/core/Table"
import TableRow from "@material-ui/core/TableRow"

import MuiTableCell from "@material-ui/core/TableCell"
import clsx from "clsx"
import { useDispatch, useSelector } from "react-redux"

import {
  Difficulty,
  Duration,
  getDifficultyDisplay,
  getDurationDisplay,
  ScheduleDateFormats,
  WorkoutSelectedTypes,
  WorkoutVideo
} from "../types"
import theme from "../../../theme"
import { Box, TableBody, Typography } from "@material-ui/core"
import WorkoutImage from "../WorkoutCard/WorkoutImage/WorkoutImage"
import { RootState } from "../../../redux/store"
import { workoutSelected } from "../browseSlice"
import moment from "moment"
import RoundButton from "../../../components/RoundButton"

const TableCell = withStyles({
  root: {
    borderBottom: "none"
  }
})(MuiTableCell)

const ratio = 9 / 16
const imgWidth = 120
const useStyles = makeStyles({

  row: {
    cursor: "pointer",
    height: "105px",
    [theme.breakpoints.down("xs")]: {
      height: "80px"
    }
  },
  rowAlt: {
    backgroundColor: "rgba(255,255, 255, 0.05)"
  },
  imageCell: {
    width: `${imgWidth + theme.spacing(10)}px`,
    [theme.breakpoints.down("xs")]: {
      display: "none"
    }
  },
  image: {
    width: `${imgWidth}px`,
    height: `${imgWidth * ratio}px`
  },
  duration: {
    [theme.breakpoints.down("sm")]: {
      display: "none"
    }
  },
  fullTitle: {
    [theme.breakpoints.up("md")]: {
      display: "none"
    }
  },
  scheduleTitle: {
    minWidth: "180px",
    width: "310px",
    [theme.breakpoints.down("sm")]: {
      display: "none"
    }
  },

  link: {
    color: theme.palette.primary.main,
    [`@media (hover: hover)`]: {
      "&:hover": {
        cursor: "pointer",
        opacity: 0.7
      }
    }
  },

  dateTitle: {
    margin: theme.spacing(8, 0, 3, 2),
    textTransform: "uppercase"
  },

  difficulty: {
    [theme.breakpoints.down("sm")]: {
      display: "none"
    }
  },
  time: {},

  subText: {
    opacity: 0.4
  },
  instructor: {
    opacity: 0.4,
    whiteSpace: "pre"
  },

  invite: {
    width: "100px",
    [theme.breakpoints.down("sm")]: {
      width: "90px",
      marginRight: theme.spacing(1)
    },
    [theme.breakpoints.up("xl")]: {
      width: "120px"
    }
  }
})


export default function ScheduleList() {

  const classes = useStyles()
  const oddRowStyle = clsx(classes.row, classes.rowAlt)
  const dispatch = useDispatch()
  const classTimeslots = useSelector((state: RootState) => state.browse.scheduledClasses)

  function groupClassTimeSlotsByDay(classTimeslotsToShow: WorkoutVideo[]) {
    return classTimeslotsToShow.reduce((map: any, obj) => {
      const key = moment(obj.scheduleInfo!.startTimeInMs).format(ScheduleDateFormats.displayDayLong)
      let classes: WorkoutVideo[] | undefined = map[key]
      if (!classes) {
        classes = []
        map[key] = classes
      }
      classes.push(obj)
      return map
    }, {})
  }

  const classTimeSlotsByDay = groupClassTimeSlotsByDay(classTimeslots)


  function classClicked(workout: WorkoutVideo, type: WorkoutSelectedTypes) {
    dispatch(workoutSelected({ workout, type , fromScheduleList: true}))
  }

  return <div>
    {Object.keys(classTimeSlotsByDay).map((keyName: string, index: number) => (
      <div key={keyName}>
        {<Typography className={classes.dateTitle} variant={"h3"}>{keyName}</Typography>}
        <Table aria-label="simple table">
          <TableBody>
            {classTimeSlotsByDay[keyName].map((row: WorkoutVideo, index: number) => (
              <TableRow key={row.scheduleInfo!.id} className={index % 2 == 0 ? oddRowStyle : classes.row}
                        onClick={() => classClicked(row, "schedule-detail")}>
                <TableCell className={classes.imageCell}>
                  <Image workoutVideo={row} className={classes.image}/>
                </TableCell>
                <TableCell className={classes.time}><Typography variant={"body1"}
                                                                display={"block"}>{moment(row.scheduleInfo!.startTimeInMs).format(ScheduleDateFormats.displayTime).toUpperCase()}</Typography></TableCell>
                <TableCell className={classes.difficulty}><Typography
                  variant={"body1"}>{getDifficultyDisplay(row.mainDifficulty as Difficulty)}</Typography></TableCell>
                <TableCell className={classes.duration}><Typography
                  variant={"body1"}>{getDurationDisplay(row.durationCategory as Duration)}</Typography></TableCell>
                <TableCell className={classes.scheduleTitle}><Typography variant={"body1"} className={classes.link}
                                                                         display={"block"}>{row.scheduleTitle}</Typography><Typography
                  variant={"subtitle1"} className={classes.instructor}
                  display={"block"}>{row.subtitle}</Typography></TableCell>
                <TableCell className={classes.fullTitle}><Typography variant={"body1"} className={classes.link}
                                                                     display={"block"}>{row.title}</Typography><Typography
                  variant={"subtitle1"} className={classes.subText}
                  display={"block"}>{getDifficultyDisplay(row.mainDifficulty as Difficulty)} • {row.subtitle}</Typography></TableCell>
                <TableCell><RoundButton className={classes.invite} selected={row.scheduleInfo!.ctaState === "join"}
                                        text={row.scheduleInfo!.ctaState == "invite" ? "Invite friends" : "Join now"}
                                        symbol={"+"}
                                        onClick={() => classClicked(row, row.scheduleInfo!.ctaState == "invite" ? "friends-invite" : "schedule-detail")}/></TableCell>
              </TableRow>
            ))}
          </TableBody>

        </Table>
      </div>
    ))}
  </div>
}

export interface ImageProps {
  workoutVideo: WorkoutVideo
  className: string
}

function Image(props: ImageProps) {

  return <Box className={props.className} ml={4}><WorkoutImage workoutVideo={props.workoutVideo} hideMeta={true}/></Box>
}