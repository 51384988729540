import { Checkbox, FormControlLabel, useTheme } from "@material-ui/core"
import React from "react"
import { CheckBoxOutlineBlank } from "@material-ui/icons"
import Typography from "@material-ui/core/Typography"
import useMediaQuery from "@material-ui/core/useMediaQuery"


export interface Props {
  checked: boolean
  label: string
  light: boolean
  onChange: (checked: boolean) => void
}

export default function StyledCheckbox(props: Props) {
  const theme = useTheme()
  const xsScreen = useMediaQuery(theme.breakpoints.down("xs"))

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.onChange(event.target.checked)
  }

  return (<FormControlLabel
    control={<Checkbox
      size={"small"}
      checked={props.checked}
      disableRipple={true}
      icon={<CheckBoxOutlineBlank style={{ color: props.light ? "#ffffff8A" : "#0101018A" }}
      />} onChange={handleChange} name="checkedA" color="primary"
    />}

    label={<Typography variant={xsScreen ? "body1" : "subtitle1"}>{props.label}</Typography>}
  />)
}