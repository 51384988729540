import React, { useState } from "react"
import Menu from "@material-ui/core/Menu"
import MenuItem from "@material-ui/core/MenuItem"
import { makeStyles } from "@material-ui/core/styles"
import { Typography } from "@material-ui/core"
import { SortType } from "../../types"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../../../redux/store"
import { sortClicked, sortWorkouts } from "../../browseSlice"
import { LinkHoverTap } from "../../../../components/AnimationEffects"

const useStyles = makeStyles((theme) => ({
  title: {
    textTransform: "none",
    display: "flex",
    justifyContent: "center",
    justifyItems: "center",
    alignItems: "center",
    textAlign: "center",
    color: theme.palette.primary.main,
    "&:hover": {
      cursor: "pointer"
    }
  },

  paper: {
    backgroundColor: "#fff",
    border: "0.5px solid rgba(0, 0, 0, 0.08)",
    [theme.breakpoints.down("xs")]: {
      border: "0.5px solid rgba(0, 0, 0, 0.18)"
    }
  },

  list: {
    color: "#000"
  },

  selectedItem: {
    color: theme.palette.primary.main
  }

}))
export default function Sort() {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const activeSort = useSelector((state: RootState) => state.browse.activeSort)
  const dispatch = useDispatch()


  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
    dispatch(sortClicked())
  }

  const handleClose = (sortType: SortType | null) => {
    setAnchorEl(null)
    if (sortType != null) {
      dispatch(sortWorkouts(sortType))
    }
  }

  return (
    <div>
      <LinkHoverTap>
        <div className={classes.title}>
          <Typography style={{ textTransform: "none" }} variant={"body2"} aria-controls="simple-menu"
                      aria-haspopup="true" onClick={handleClick}>
            Sort by: {activeSort.toString()}
          </Typography>
        </div>
      </LinkHoverTap>
      <Menu
        classes={{
          paper: classes.paper,
          list: classes.list
        }}
        id="simple-menu"
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        getContentAnchorEl={null}
        keepMounted={true}
        elevation={4}
        open={Boolean(anchorEl)}
        onClose={() => handleClose(null)}
      >
        <LinkHoverTap><MenuItem classes={{ selected: classes.selectedItem }} selected={activeSort === SortType.difficulty}
                                onClick={() => handleClose(SortType.difficulty)}>{SortType.difficulty.toString()}</MenuItem></LinkHoverTap>
        <LinkHoverTap><MenuItem classes={{ selected: classes.selectedItem }} selected={activeSort === SortType.new}
                                onClick={() => handleClose(SortType.new)}>{SortType.new.toString()}</MenuItem></LinkHoverTap>
        <LinkHoverTap><MenuItem classes={{ selected: classes.selectedItem }} selected={activeSort === SortType.old}
                                onClick={() => handleClose(SortType.old)}>{SortType.old.toString()}</MenuItem></LinkHoverTap>
        <LinkHoverTap><MenuItem classes={{ selected: classes.selectedItem }}
                                onClick={() => handleClose(SortType.popular)}>{SortType.popular.toString()}</MenuItem></LinkHoverTap>
        <LinkHoverTap><MenuItem classes={{ selected: classes.selectedItem }} selected={activeSort === SortType.category}
                                onClick={() => handleClose(SortType.category)}>{SortType.category.toString()}</MenuItem></LinkHoverTap>
      </Menu>
    </div>
  )
}

