import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Box, Divider } from "@material-ui/core"
import { FilterType } from "../../types"
import Filter from "./Filter"
import { motion } from "framer-motion"


export interface Props {
  light: boolean,
  activeFilters: any,
  onOptionChanged: (filterType: FilterType, optionId: string, optionDisplay: string, checked: boolean) => void

}

const variants = {
  open: {
    opacity: 1
  },
  closed: {
    opacity: 0
  }
}

const FilterStrip = (props: Props) => {
  const data = useStaticQuery(graphql`
      {
          allDifficulty(sort: {order: ASC, fields: level}) {
              nodes {
                  id
                  display
              }
          }
          allDuration {
              nodes {
                  id
                  display
              }
          }
          allWorkoutCategory {
              nodes {
                  id
                  display
              }
          }
          allInstructor(sort: {fields: name, order: ASC}) {
              nodes {
                  id
                  name
                  shortName
              }
          }
          allEquipmentCategory(sort: {fields: display, order: DESC}) {
              nodes {
                  id
                  display
              }
          }
      }
  `)


  function onOptionChanged(filterType: FilterType, id: string, display: string, active: boolean) {
    props.onOptionChanged(filterType, id, display, active)
  }

  return <div>
    <Filter title={`Classes`} filterType={FilterType.category} options={data.allWorkoutCategory.nodes}
            optionIdKey={`id`}
            optionDisplayKey={`display`} optionsCheck={props.activeFilters[FilterType.category]}
            onOptionChanged={onOptionChanged} light={props.light}/>
    {divider(props.light)}

    <Box mt={2}>
      <Filter title={`Difficulty`} filterType={FilterType.difficulty} options={data.allDifficulty.nodes}
              optionIdKey={`id`} optionDisplayKey={`display`} optionsCheck={props.activeFilters[FilterType.difficulty]}
              onOptionChanged={onOptionChanged} light={props.light}/>
    </Box>
    {divider(props.light)}

    <Box mt={2}>
      <Filter title={`Duration`} filterType={FilterType.duration} options={data.allDuration.nodes} optionIdKey={`id`}
              optionDisplayKey={`display`} optionsCheck={props.activeFilters[FilterType.duration]}
              onOptionChanged={onOptionChanged} light={props.light}/>
    </Box>
    {divider(props.light)}

    <Box mt={2}>
      <Filter title={`Equipment needed?`} filterType={FilterType.equipment}
              options={data.allEquipmentCategory.nodes} optionIdKey={`id`} optionDisplayKey={`display`}
              optionsCheck={props.activeFilters[FilterType.equipment]} onOptionChanged={onOptionChanged}
              light={props.light}/>
    </Box>
    {divider(props.light)}

    <Box mt={2}>
      <Filter title={`Instructor`} filterType={FilterType.instructor} options={data.allInstructor.nodes}
              optionIdKey={`id`} optionDisplayKey={`name`} optionsCheck={props.activeFilters[FilterType.instructor]}
              onOptionChanged={onOptionChanged} light={props.light}/>
    </Box>

  </div>
}


export default FilterStrip


function divider(light: boolean | null) {
  return <motion.div variants={variants}><Divider
    style={{ backgroundColor: light ? "#fff" : "#000000", opacity: light ? 0.5 : 0.09 }}/></motion.div>
}