import { styled } from "@material-ui/core/styles"
import React from "react"
import FeaturedTitle from "../FeaturedTitle/FeaturedTitle"
import FeaturedStrip from "../FeaturedStrip/FeaturedStrip"
import { Box } from "@material-ui/core"

const Container = styled("div")(({ theme }) => ({
  backgroundColor: "black",
  width: "100%",
  overflowX: "hidden",
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {}
}))

const Holder = styled("div")(({ theme }) => ({
  padding: theme.spacing(9, 0, 0, 0),
  display: "flex",
  justifyContent: "center",
  justifyItems: "center",
  width: "100%",
  [theme.breakpoints.down("xs")]: {
    padding: theme.spacing(7, 0, 0, 0),
    margin: theme.spacing(0, 1),
    paddingLeft: 0
  }
}))

export default function FeaturedSection() {
  return (
    <Container>
      <Holder>
        <Box mt={{ xs: 1, sm: 2, md: 4 }} width={{ xs: "100%", sm: "95%", md: "85%", lg: "75%", xl: "75%" }}>
          <Box ml={{ xs: 1, sm: 0 }}>
            <FeaturedTitle/>
          </Box>
          <FeaturedStrip/>
        </Box>
      </Holder>
    </Container>
  )
}