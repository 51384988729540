import { makeStyles } from "@material-ui/core/styles"
import React from "react"
import { Difficulty, getDifficultyDisplay, WorkoutVideo } from "../types"
import WorkoutImage from "./WorkoutImage/WorkoutImage"
import clsx from "clsx"
import Typography from "@material-ui/core/Typography"

const useStyles = makeStyles((theme) => ({
  root: {
    "&:hover": {
      cursor: "pointer",
      color: "white"
    }
  },
  title: {
    marginTop: theme.spacing(1),
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(1)
    }
  },

  lightModeTextColor: {
    color: "black"
  },

  subtitle: {
    marginTop: theme.spacing(0),
    opacity: 0.4
  },
  titleHolder: {
    width: "100%"
  },
  button: {
    marginTop: theme.spacing(2.5),
    width: "75px"
  },
  titleText: {},
  rating: {
    paddingBottom: theme.spacing(0.5),
    [theme.breakpoints.up("md")]: {
      float: "right",
      paddingBottom: 0
    }
  },
  hide: {
    display: "none"
  }
}))


export default function WorkoutCard({ workoutVideo, lightMode, onClick, fixed, featured, inviteFriendsClicked }: {
  workoutVideo: WorkoutVideo, lightMode: boolean, onClick: (w: WorkoutVideo) => void, fixed?: boolean, featured?: boolean, inviteFriendsClicked?: () => void | null
}) {
  const classes = useStyles()
  const subtitle = `${featured ? getDifficultyDisplay(workoutVideo.mainDifficulty as Difficulty) + " • " + workoutVideo.subtitle : workoutVideo.subtitle}`


  return (
    <div onClick={() => onClick(workoutVideo)} className={classes.root}>
      <WorkoutImage workoutVideo={workoutVideo} fixed={fixed} featured={featured}
                    inviteFriendsClicked={inviteFriendsClicked}/>
      <Typography variant={"body1"} className={clsx({
        [classes.title]: true,
        [classes.lightModeTextColor]: lightMode
      })}>
        {workoutVideo.title}
      </Typography>
      <Typography variant={"subtitle1"} className={clsx({
        [classes.title]: true,
        [classes.subtitle]: true,
        [classes.lightModeTextColor]: lightMode
      })}>{subtitle}</Typography>
    </div>
  )
}
