import { makeStyles } from "@material-ui/core/styles"
import React from "react"
import Typography from "@material-ui/core/Typography"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import { TapEffect } from "../../../../components/AnimationEffects"

const useStyles = makeStyles((theme) => ({
  root: {
    position: "fixed",
    bottom: 0,
    left: 0,
    width: "100%",
    height: "42px",
    zIndex: 5,
    backgroundColor: "#000",
    display: "none",
    [theme.breakpoints.down("xs")]: {
      display: "block"
    }

  },

  inner: {
    display: "flex",
    alignItems: "center",
    justifyItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
    [`@media (hover: hover)`]: {
      "&:hover": {
        cursor: "pointer",
        color: theme.palette.primary.main
      }
    }

  },

  filterImage: {
    height: `16px`,
    width: `16px`,
    marginLeft: theme.spacing(1)
  }
}))

export interface Props {
  clicked: () => void
}

export default function FilterButton(props: Props) {
  const data = useStaticQuery(graphql`
      {
          file(relativePath: {eq: "filter.png"}) {
              childImageSharp {
                  fluid(webpQuality: 100) {
                      ...GatsbyImageSharpFluid_withWebp_tracedSVG
                  }
              }
          }
      }
  `)

  const classes = useStyles()

  return (
    <div className={classes.root} onClick={props.clicked}>
      <TapEffect>
        <div className={classes.inner}>
          <Typography variant={"h3"}>FILTER</Typography>
          <Img className={classes.filterImage} fluid={data.file.childImageSharp.fluid}/>
        </div>
      </TapEffect>
    </div>)
}