import { styled } from "@material-ui/core/styles"
import React from "react"
import Header from "./Header"
import WorkoutsGrid from "./WorkoutsGrid/WorkoutsGrid"
import { Box } from "@material-ui/core"
import SideFilter from "./Filter/SideFilter"
import OverlayFilter from "./Filter/OverlayFilter"


const Container = styled("div")(({ theme }) => ({
  height: "auto",
  display: "block",
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {}
}))

const Body = styled("div")(({ theme }) => ({
  height: "auto",
  display: "flex",
  width: "100%",
  marginTop: theme.spacing(2),
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {
    marginTop: theme.spacing(-1),
    display: "block"
  }
}))

export default function WorkoutsSection() {

  return (
    <Container>
      <Header/>
      <Body>
        <Box mt={1}>
          <SideFilter/>
        </Box>
        <OverlayFilter/>
        <WorkoutsGrid/>
      </Body>
    </Container>
  )
}