import React from "react"
import { makeStyles, styled } from "@material-ui/core/styles"
import { Typography } from "@material-ui/core"

const Container = styled("div")(({ theme }) => ({
  display: "block"

}))

const useStyles = makeStyles((theme) => ({
  featured: {
    display: "inline-block",
    "textTransform": "uppercase",
    "whiteSpace": "pre-wrap"
  },


  workouts: {
    width: "auto",
    padding: theme.spacing(0.5, 1),
    marginTop: theme.spacing(0.5),
    backgroundColor: "white",
    color: "black"
  },

  message: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      display: "none"
    }
  }
}))

export default function FeaturedTitle() {
  const classes = useStyles()
  return (
    <Container>
      <Typography variant={"h2"} className={classes.featured}>upcoming classes</Typography>
      <div>
        <Typography variant={"body2"}>Join us for a live class</Typography>
      </div>
    </Container>
  )
}