import { makeStyles } from "@material-ui/core/styles"
import React from "react"
import WorkoutCard from "../../WorkoutCard/WorkoutCard"
import { Box, Grid } from "@material-ui/core"
import { useDispatch, useSelector } from "react-redux"
import { WorkoutSelectedTypes, WorkoutVideo } from "../../types"
import { RootState } from "../../../../redux/store"
import { workoutSelected, toggleShowSchedule } from "../../browseSlice"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import useTheme from "@material-ui/core/styles/useTheme"
import ScheduleList from "../../Scheduled/ScheduleList"
import StyledButton from "../../../../components/StyledButton"
import useToggle from "../../../../hooks/useToggle"

const useStyles = makeStyles((theme) => ({
  root: {
    height: "auto",
    overflow: "hidden",
    marginTop: theme.spacing(4),
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      "-ms-overflow-style": "none",  /* IE and Edge */
      "scrollbar-width": "none",  /* Firefox */
      "&::-webkit-scrollbar": {
        display: "none"
      }
    },
    [theme.breakpoints.down("xs")]: {
      overflowX: "none",
      margin: theme.spacing(1, 0),
      paddingRight: theme.spacing(2),
      flexWrap: "wrap"
    }
  },
  grid: {
    height: "auto",
    [theme.breakpoints.up("sm")]: {
      marginBottom: theme.spacing(1)
    }
  },

  moreContainer: {
    marginTop: theme.spacing(4),
    display: "none",
    justifyContent: "center",
    justifyItems: "center",
    alignItems: "center",
    textAlign: "center",
    color: theme.palette.primary.main,
    "&:hover": {
      cursor: "pointer"
    }, [theme.breakpoints.up("sm")]: {
      display: "flex"
    }
  },

  scheduleButton: {
    width: "280px",
    margin: `${theme.spacing(4)}px auto ${theme.spacing(2)}px auto`,
    [theme.breakpoints.up("xl")]: {
      width: "320px"
    },
    [theme.breakpoints.down("xs")]: {
      marginBottom: "0px"
    }
  },

  hide: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "none"
    }
  }
}))


export default function FeaturedStrip() {
  const classes = useStyles()
  const dispatch = useDispatch()
  const theme = useTheme()
  const xsScreen = useMediaQuery(theme.breakpoints.down("xs"))

  const allFeaturedWorkouts = useSelector((state: RootState) => state.browse.scheduledClasses)
  const showSchedule = useSelector((state: RootState) => state.browse.showScheduleActive)
  const featuredWorkouts = allFeaturedWorkouts.slice(0, xsScreen ? 4 : 3)

  const isMediumScreen = useMediaQuery(theme.breakpoints.only("md"))
  const isSmallerThanMedium = useMediaQuery(theme.breakpoints.down("sm"))
  const spacing = isMediumScreen ? 3 : (isSmallerThanMedium ? 2 : 5)

  function workoutClicked(workout: WorkoutVideo, type: WorkoutSelectedTypes) {
    dispatch(workoutSelected({ workout, type }))
  }


  return (
    <div className={classes.root}>
      <Grid className={classes.grid} container={true} spacing={spacing}>
        {featuredWorkouts?.map((workout, index, workouts) => (
          <Grid item={true} xs={6} sm={4} md={4} key={workout.scheduleInfo!.id}>
            <Box mb={{ xs: 1.5, sm: 0 }}>
              <WorkoutCard
                lightMode={false}
                workoutVideo={workout}
                featured={true}
                inviteFriendsClicked={() => workoutClicked(workout, "friends-invite")}
                onClick={() => workoutClicked(workout, "schedule-detail")}/>
            </Box>
          </Grid>
        ))}
      </Grid>
      {showSchedule && <Box mt={4}><ScheduleList/></Box>}
      <StyledButton className={classes.scheduleButton} text={!showSchedule ? `see full schedule` : `Close Schedule`}
                    onClick={() => dispatch(toggleShowSchedule())}/>
    </div>
  )
}